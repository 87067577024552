/*BACKGROUND COMPONENT*/
.bgimg-container {
    width: 100%;
    height: 280px;
    background-size: cover;
    /* Ensures the image covers the entire container */
    background-position: center;
    /* Centers the image */
    background-repeat: no-repeat;
    /* Prevents the image from repeating */
    display: flex;
  align-items: center;
    border-radius: 0px;
    object-fit: cover;
}

.bgimg-container h1 {
    color: aliceblue;
    position: absolute;
    margin-left: 25px;
    width: 450px;
    font-weight: 600;
    font-size: 2.8rem;
    text-transform: uppercase;
}


@media (max-width: 768px) {
    .bgimg-container h1 {
        font-size: 1.0rem;
        width: 250px;
        margin-left: 10px;
    }

}

@media (max-width: 992px) {
    .bgimg-container h1 {
        font-size: 2.3rem;
    }
}

/*Internationl COMPONENT*/
.text-box-international {
    text-align: center;
    background-color: rgb(10, 47, 79);
}

.text-box-international p {
    font-size: 1.2rem;

    color: aliceblue;
    word-spacing: 2px;
}

.generator-image {
    width: 100%px;
    height: 440px;
    overflow: hidden;
    border-radius: 30px;
    border: 1px solid black;
}


.generator-image img {
    width: 100%;
    height: 100%;

}

.txt-international {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    background-color:#D9D9D9;
    border-radius: 30px;
}

.txt-international p {
    font-size: 1.3rem;
    padding: 1.2rem;
}




@media (max-width: 992px) {
    .text-box-international p {
        font-size: 1rem;
        /* Medium screens (e.g., tablets) */
    }

    .txt-international p {
        font-size: 1.0rem;

    }
}

@media (max-width: 768px) {
    .text-box-international p {
        font-size: 0.9rem;
        /* Small screens (e.g., large smartphones) */
    }

    .txt-international p {
        font-size: 0.9rem;

    }
}

@media (max-width: 576px) {
    .text-box-international p {
        font-size: 0.8rem;
        /* Extra small screens (e.g., small smartphones) */
    }

    .generator-image {
        width: 430px;
        height: 380px;
        border-radius: 0px;
    }

    .txt-international p {
        font-size: 0.8rem;

    }
}


/*ABOUT US PAGE STYLING*/

.image-container {
    position: relative;
    width: 90%;
    /* Adjust as needed */
    height: 600px;
    /* Adjust as needed */
    margin-left: auto;
    /* Align the container to the right */
    display: flex;
    justify-content: flex-end;
    /* Align images to the right within the container */
}

.image {
    position: absolute;
    transition: all 0.3s ease-in-out;
    /* Optional: for hover effects */
}

/* Position the first image */
.image1 {
    width: 90%;
    height: 90%;
    top: 0;
    left: auto;
    /* Align to the right */
    right: 0;
    z-index: 1;
    /* Higher z-index means it will be on top */
}

/* Position the second image to overlap the first */
.image2 {
    right: 130px;
    bottom: 0;
    width: 80%;
    height: 70%;
    z-index: 2;
}

/* Optional: Hover effect to bring the image to the front */
.image:hover {
    z-index: 3;
    transform: scale(1.1);
}



.about-heading-text {
    margin: 5px auto;
    /* Center the container horizontally */
    padding: 10px 20px;
    /* Add padding for better spacing */
    background-color: rgba(9, 46, 76, 255);
    color: aliceblue;
    text-align: center;
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    
}
@media (max-width: 550px) {
    .about-heading-text {
        max-width: 100%;
        margin-right: 5px;
        margin-left: 5px; /* Adjust margin for small screens */
    }
}

.about-heading-text p {
    font-size: 1.2rem;
    margin: 0;
    /* Remove default margins */
}

@media (min-width: 992px) {
    .image-container {
        margin-left: auto;
        margin-right: 0;
    }
}

@media (min-width: 991px) and (max-width: 1176px) {
    .card-comp h4 {
        height: 10px;
    }
}

.about-text-img-side {
    border-radius: 30px;
    width: 90%;
    height: 100%;
    /* Ensure it takes the full height of its parent */
    border: 1px solid gray;
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    padding: 20px;
}


.button {
    color: aliceblue;
    background-color: rgb(0,70,155);
    padding: 14px;
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 8px;
    border:none;
    
    width: 200px;
    margin-top: auto;
    /* Push the button to the bottom */
}

.button:hover {
    text-decoration: none;
    background-color:  rgb(0, 54, 121); ;
}

.about-text-img-side h4 {
    color: #202e5f;
    ;
}

.about-text-img-side h1 {
    font-weight: 500;
}

.about-text-img-side p {
    color: rgb(27, 27, 27);
}





/*AfterSales*/
.support-container p {
    color: #565656;
}

.heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.heading hr {
    width: 60%;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    border-top: 1px solid rgb(103, 103, 103);
}


.overlay-heading {
    text-align: center;

    color: #f5f8fb;
    /* Optional color for the heading */
}





.card-comp {
    background-color: #f5f5f5;
    /* Example background color */

    width: 100%;
    /* Full width of the parent container */
    max-width: 300px;
    /* Set a maximum width for the card */
    height: auto;
    /* Fixed height */
    min-height: 380px;
    /* Optional: ensures a minimum height for the card */
    margin: 0 auto;
    /* Center the card horizontally if needed */
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.4);
    /* Shadow for visual effect */
}

.card-comp h4 {
    text-align: center;
    height: 75px;
    padding: 15px;
}

.card-comp ul {
    list-style: none;
    /* Remove default list styles */
    padding: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove default margin */
}

.card-comp li {

    display: flex;
    border-radius: 4px;
    /* Optional rounded corners for list items */
}

.card-comp li img {

    width: 30px;
    /* Set width of the image */
    height: 30px;
    /* Set height of the image */
}


/* Main container */
.main-container {
    position: relative;
    padding: 0;
    width: 100%;
    overflow: hidden;
}

/* Background layer */
.background-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* Cover half of the container */
    background-color: rgb(10, 47, 79);
    /* Example background color */
    z-index: 0;
    /* Make sure it stays behind the content */
}

/* Content area */
.content {
    position: relative;
    z-index: 1;
    /* Make sure it appears above the background layer */
}




/* Optional adjustments for card-item */
.card-item {
    padding: 10px;
    /* Adjust padding if needed */
}


.content h1 {
    color: aliceblue;
}


/*Action Service*/
.action-service-image-container {
    width: 100%;
    text-align: center;
}

.action-service-image-container img {
    max-width: 100%;
    /* Ensure the image scales down within the container */
    height: auto;
    /* Maintain aspect ratio */
}

.text-container {
    padding: 1rem;
    /* Add padding for spacing */
    color: #3f3f3f;
    /* Ensure text is readable against the background */
}

.text-container h4 {
    margin-bottom: 1rem;
    /* Add space below the heading */
}

.text-container p {
    margin-bottom: 1rem;
    /* Add space below the paragraph */
}

.text-container ul {
    list-style-type: disc;
    /* Use disc bullets */
    margin-left: 0px;
    /* Add margin for bullet alignment */
}

.text-container li {
    margin-bottom: 0.5rem;
    /* Add space between list items */
}

/* Product Details */
.fff {
    height: 500px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-container-product {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-p {
    position: absolute;
    transition: all 0.3s ease-in-out;
    /* Optional: for hover effects */
}

/* Position the first image */
.image1-p {
    width: 80%;
    height: 90%;
    top: 0;
    right: 0;
    z-index: 1;
    object-fit: cover;
    /* Ensures the image covers its container */
}

/* Position the second image to overlap the first */
.image2-p {
    right: 200px;
    bottom: 0;
    width: 60%;
    height: 70%;
    z-index: 2;
    object-fit: cover;
    /* Ensures the image covers its container */
}

.product-text-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-text {
    height: 90%;
    width: 100%;
}

.product-text h2 {
    font-weight: 900;
    

}
@media (max-width:992px) {
    .product-text h2 {
        font-weight: 900;
        font-size: 1.7rem !important;
        
    
    }
   
}
@media (max-width:440px) {
    .product-text h2 {
        font-weight: 900;
        font-size: 1.5rem !important;
        
    
    }
    .product-text p {
        font-size: 0.9rem !important;
    }
   
}
.product-text h4 {
    font-weight: 600;
    color: #202e5f;
}

.product-text p {
    font-size: 1.1rem;
}


/*Contact Page*/
.contact-container {
    display: flex;
    align-items: center;
}

.icon-container {
    width: 40px;
    /* Adjust width as needed */
    height: 40px;
    /* Adjust height as needed */
}

.jjj {
    margin: 0;
    line-height: 1.5;
    /* Ensure proper line spacing */
}

.Contact-Info p {
    font-size: 1.1rem;


}

.Contact-Info {
    background-color: rgba(9,46,76,255);
    color: aliceblue;
}

.Contact-Info hr {
    color: aliceblue;
    border: 1px solid aliceblue;
}

.Form-Container {
    border: 1px solid gainsboro;
    border-radius: 15px;
    height: 100%;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 1.0rem;
}

/* Input and textarea styling */
input,
textarea {
    width: 90%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.Form-Container button{
    width: 90%;
    padding: 5px;
    font-size: 1.1rem;
    border-radius: 6px;
    color: aliceblue;
    border: none;
    background-color: #3f3f3f;

}











/* Medium Screen (768px - 991px) */
@media (max-width: 991px) {
    .about-heading-text p {
        font-size: 1.0rem;
        margin: 0;
        /* Remove default margins */
    }

    .image-container {
        height: 500px;
        /* Adjust as needed */
    }

    .about-text-img-side {
        width: 100%;
        height: 100%;
        /* Ensure it takes the full height of its parent */
        border: none;
        border-radius: 0px;
        box-shadow: none;
    }

    .about-text-img-side h4 {
        font-size: 1.5rem;
    }

    .about-text-img-side h1 {
        font-size: 2.0rem;
    }

    .about-text-img-side p {
        font-size: 1.0rem;
    }

    .button {

        padding: 12px;
        font-size: 1.0rem;
        cursor: pointer;
        border-radius: 8px;
        width: 150px;
        margin-top: auto;
        /* Push the button to the bottom */
    }

    .support-container p {
        font-size: 0.9rem;
    }

    .support-container h5 {
        font-size: 1.2rem;
    }

    .heading h1 {
        font-size: 2.0rem;
    }

    .background-layer {
        height: 100%;
    }

    .product-text p {
        font-size: 1.0rem;
    }

    .product-text h2 {
        font-size: 1.7rem;

    }

    .product-text h4 {
        font-size: 1.3rem;
    }


}

/* Small Screen (576px - 767px) */
@media (max-width: 767px) {
    .about-heading-text p {
        font-size: 0.9rem;
        margin: 0;
        /* Remove default margins */
    }

    .image2 {
        right: 110px;
        bottom: 0;
        width: 80%;
        height: 70%;
    }

    .about-text-img-side {
        width: 100%;
        height: 100%;
        /* Ensure it takes the full height of its parent */
        border: none;
        border-radius: 0px;
        box-shadow: none;
    }

    .about-text-img-side h4 {
        font-size: 1.5rem;
    }

    .about-text-img-side h1 {
        font-size: 2.0rem;
    }

    .about-text-img-side p {
        font-size: 1.0rem;
    }

    .button {

        padding: 12px;
        font-size: 1.0rem;
        cursor: pointer;
        border-radius: 8px;
        width: 150px;
        margin-top: auto;
        /* Push the button to the bottom */
    }

    .support-container p {
        font-size: 0.8rem;
    }

    .support-container h5 {
        font-size: 1.2rem;
    }

    .heading h1 {
        font-size: 1.8rem;
    }

    .background-layer {
        height: 100%;
    }

    .card-comp li {
        font-size: 1.0rem;
    }

    .card-comp h4 {
        font-size: 1.5rem;
    }

    .fff {
        height: 400px;
        padding: 10px;
        padding-right: 50px;

    }

    .image-container-product {
        width: 100%;
        height: 100%;
    }

    /* Position the second image to overlap the first */
    .image2-p {
        right: 180px;
        bottom: 0;
        width: 60%;
        height: 70%;
        /* Ensures the image covers its container */
    }



}

/* Extra Small Screen (up to 575px) */
@media (max-width: 575px) {
    .about-heading-text p {
        font-size: 0.8rem;
        margin: 0;
        /* Remove default margins */
    }

    .image1 {
        width: 100%;
        height: 80%;
        top: 0;
        left: auto;
        /* Align to the right */
        right: 0;
        z-index: 1;
        /* Higher z-index means it will be on top */
    }

    .image2 {
        right: 50px;
        bottom: 10px;
        width: 90%;
        height: 50%;
    }

    .about-text-img-side h4 {
        font-size: 1.4rem;
    }

    .about-text-img-side h1 {
        font-size: 1.8rem;
    }

    .about-text-img-side p {
        font-size: 1.0rem;
    }


    .button {

        padding: 12px;
        font-size: 1.0rem;
        cursor: pointer;
        border-radius: 8px;
        width: 150px;
        margin-top: auto;
        /* Push the button to the bottom */
    }

    .heading hr {
        width: 100%;
    }



    .card-comp {
        width: 300px;

    }

    .content h1 {
        font-size: 1.9rem;
    }

    .fff {
        height: 380px;
        padding: 15px;
        padding-right: 10px;

    }

    /* Position the second image to overlap the first */
    .image2-p {
        right: 110px;
        bottom: 0;
        width: 65%;
        height: 70%;
        /* Ensures the image covers its container */
    }

    .Contact-Info p {
        font-size: 1.0rem;
    
    
    }

}

/*Events Page*/
.image-container-events {
    position: relative;
    width: 100%;
    height: 100%; 
}
.image-e{
    position: absolute;

}
.event-i{
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;

}

.event-text {
    height: 90%;
    width: 100%;
}

/* EventVideos.css */
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
    padding: 20px;
   
  }
  
  .video-player {
    height: 100%; /* Set the height of the video to fill most of the container */
    width: 85%; 
    object-fit: fill;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .event-vid-text{
    text-align: center;

  }

  @media (max-width: 768px) {
    .video-player {
       
        width: 80%; 
        object-fit: fill;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
      .video-container {
        height: 470px;
        padding: 20px;
       
      }

  }

  @media (max-width: 425px) {
    .video-container {
        height: 450px;
        padding: 20px;
       
      }
      .video-player {
       
        width: 95%; 
        object-fit: fill;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
      .event-vid-text{
        text-align: center;
        margin-bottom: 0px;
    
      }
  }