.nav-img {
    max-width: 340px;
    width: 100%;
    background-color: rgba(193, 213, 231, 0);
    overflow: hidden;
}
.nav-img img{
    height: 100%;
}
.nav-color{
    background-color:rgb(10, 47, 79);
}

.dropdown-menu-offcanvas{
    background-color: transparent;
    border: none;
    margin-top: 0px;
}


.nav-item {
    font-size: 1.15rem;
    margin-top: 3px;
    margin-bottom: 3px;
}

.nav-link {
    font-weight: 350;
    color: #ffffff;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.lni-grid-alt {
    font-size: 1.5rem;
    color: aliceblue;
}

.lni-cross-circle {
    color: aliceblue;
    font-size: 2rem;
}


.offcanvas .dropdown-menu {
    text-align: left; /* Align text inside dropdown menus to the left */
}

.offcanvas .dropdown-toggle {
    text-align: left; /* Align dropdown toggle text to the left */
    padding-left: 0; /* Adjust padding if needed */
    padding-right: 0; /* Remove any extra padding if needed */
}

.offcanvas {
    max-height: 100vh; /* Set maximum height */
    background-color: rgba(10, 47, 79, 0.962);
    overflow-y: auto; /* Enable vertical scrolling */
}

.dropdown-item {
    padding: 10px;
    width: 100%;
    color: #cbcbcb;
    font-size: 1.0rem;
    font-weight: 450;

}

.dropdown-item:active{
    color: #616161;
    background-color: rgba(10, 47, 79, 0.497);

}

.dd{
    color: #dddddd;
    border-width: 2px;
    margin: 0;
}
@media (max-width:462px) {
    .nav-img {
        max-width: 290px;
        width: 100%;
        background-color: rgb(10, 47, 79);
        margin: 0px;
        padding: 8px;
        overflow: hidden;
    }


}

@media (max-width:366px) {
    .nav-img {
        max-width: 230px;
        width: 100%;
        background-color: rgb(10, 47, 79);
        margin: 0px;
        padding: 9px;
        overflow: hidden;
    }


}

@media (min-width:992px) {
    .nav-img {
        max-width: 350px;
        width: 100%;
        background-color: rgb(10, 47, 79);
        margin: 0px;
        padding: 12px;
        overflow: hidden;
    }
    .nav-color{
        background-color:rgb(10, 47, 79);
    }
    

    .dropdown>.dropdown-menu {
        visibility: hidden;
        opacity: 0;
        display: block;
        padding: 0px;
        margin: 0px;
        transition: all 0.1s ease-out;
    }
    .dropdown-divider{
        height: 1px;
        margin: 0px;
        margin-top: 0px;
    }
    .custom-line {
        border-top: 4px solid rgb(18, 78, 130);
    }


    .dropdown:hover>.dropdown-menu {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease-in-out;
    }

    .dropdown-item {
        color: #616161;
        padding: 12px;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.1rem;
        font-weight: 400;
    }

    .dropdown-menu {
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
        border: none;
    }

    .dropdown-item:hover {
        color: #FFF;
        background-color: rgba(10, 47, 79, 0.497);
        transition: all 0.2s ease-in-out;
    }
    .single{
        position: relative;
        display: inline-block;
        text-decoration: none;
    }





    .single::after {
        content: "";
        display: block;
        height: 2px; /* Thickness of the underline */
        width: 0;
        background: #bebebe; /* Color of the underline */
        transition: width 0.3s ease;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    
    .single:hover::after,
    .single.active::after {
    width: 100%;
}
}







/*Slider*/
.carousel-image {
    height: 625px;
    /* Set your desired height */
    object-fit: cover;
    filter: blur(2px);  /* Apply the blur effect */
    -webkit-filter: blur(2px);  /* For Safari support */
    /* Ensures the image covers the container without stretching */
}

.carousel-item {
    position: relative;
    width: 100%;
}

.carousel-caption {
    position: absolute;
    
    
    width: 80%;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    align-items: center;
    color: white; /* Adjust text color if needed */
    z-index: 10; /* Make sure the caption is above the image */
}

.caption{
    padding-top: 6px;
    padding-right: 7px;
    padding-right: 7px;
    color:rgb(249, 249, 249);
    width: 60%;
    background-color:rgba(10, 47, 79, 0.436); ;
    

}
.caption p{
    font-size: 1.2rem;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 450;
}
/*responsiveness*/
#mobile {
    display: none;
}



/*Image Slider*/

.text-reveal h4 {
    background-color: transparent;
    font-size: 4.5rem;
    color: rgb(10, 47, 79);
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 400;
}

.text-reveal h1 {
    font-family:Arial, Helvetica, sans-serif;
    font-size: 4.70rem;
    background-color: transparent;
    color: rgb(10, 47, 79);
    font-weight: bold;
}



@media screen and (min-width: 880px) and (max-width: 1100px) {
    .text-reveal h4 {
        font-size: 3.5rem;
    }

    .text-reveal h1 {
        font-size: 3.70rem;
    }
    
    .caption{
        width: 70%;
        
    
    }

    .caption p{
        font-size: 1.3rem;
    }
}

@media screen and (min-width: 700px) and (max-width: 880px) {

    .text-reveal h4 {
        font-size: 2.7rem;
    }

    .text-reveal h1 {
        font-size: 3.0rem;
    }

    .caption{
        width: 80%;
        
    
    }

    .caption p{
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 560px) and (max-width:700px) {

    .text-reveal h4 {
        font-size: 2.3rem;
    }

    .text-reveal h1 {
        font-size: 2.4rem;
    }
    .caption{
        width: 80%;
        
    
    }

    .caption p{
        font-weight: 450;
        font-size: 1.0rem;
    }
}

@media screen and (max-width:560px) {

    .Tagline {
        margin-bottom: 130px;

    }

    .carousel-image {
        height: 425px;
    }

    .text-reveal h4 {
        font-size: 1.7rem;
    }

    .text-reveal h1 {
        font-size: 1.75rem;
    }
    .caption{
        width: 80%;
        
    
    }

    .caption p{
        font-size: 0.85rem;
        font-weight: 450;
        

    }
}

@media screen and (max-width:420px) {
    .carousel-image {
        height: 425px;
    }

    .Tagline {
        margin-bottom: 130px;

    }

    .text-reveal h4 {
        font-size: 1.3rem;
    }

    .text-reveal h1 {
        font-size: 1.34rem;
    }
    .caption{
        width: 80%;
        
    
    }

    .caption p{
        font-size: 0.6rem;
        font-weight: 450;
    }
}


@media screen and (max-width:1100px) {





    #mobile {
        display: block;
    }

    #mobile i {
        font-size: 24px;
        color: rgb(255, 255, 255);
        cursor: pointer;
    }


}

.nav-img-sm{
    max-width: 200px;
    width: 100%;
    background-color: rgba(10, 47, 79, 0);
    margin: 0px;
    padding-right: 5px;
    padding-left: 5px;
    overflow: hidden;
    height: 60px;

}