.Empower-Logo{
    max-width: 450px;
    width: 100%;
    height: 150px;
        border: none;
        display: flex;
}
.para{
    color: rgb(35, 138, 164);
    font-size: 1.0rem;
}
.Empower-Logo img{
    background-color:transparent;
    border: none;
    padding: 18px;
    align-items: center;
}

.box hr{
    color: aliceblue;
    border: 1px solid whitesmoke;
    width: 80%;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 15px;
}
.box{
    color: aliceblue;
}
.main{
    background-color:rgba(9,46,76,255);
}

a{
    text-decoration: none;
    color: white;
}
a:hover{
    color: rgb(230, 230, 230);
}


@media screen and (max-width:420px) {
    .Empower-Logo{
        
        width: 100%;
         background-color: aqua;
        height: 150px;
        overflow: hidden;
    }
    .Empower-Logo img{
        background-color:rgba(9,46,76,255);
        width: 100%;
        height: 100%;
        border: none;
    }
    
}

