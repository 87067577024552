/*Scroller Infinite*/
.AppContainer {
    width: 100%;
    height: 14vh;
    color: blue;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        height: 11vh;
    }

    @media (max-width: 480px) {
        height: 9vh;
    }
}

.Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Marquee {
    display: flex;
    width: 100%;
    user-select: none;
    overflow: hidden;
}

.MarqueeGroup {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;
    animation: scrollX 20s linear infinite;
}

.ImageGroup {
    display: grid;
    place-items: center;
    width: clamp(10rem, 1rem + 20vmin, 25rem);
    padding: calc(clamp(5rem, 1rem + 5vmin, 15rem) / 11);

    @media (max-width: 768px) {
        width: clamp(6rem, 1rem + 16vmin, 15rem);
        padding: calc(clamp(3rem, 1rem + 5vmin, 15rem) / 11);
    }

    @media (max-width: 480px) {
        width: clamp(5rem, 1rem + 16vmin, 12rem);
        padding: calc(clamp(5rem, 1rem + 5vmin, 15rem) / 11);
    }
}

.Image {
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    aspect-ratio: 16/9;
    padding: 5px 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    @media (max-width: 480px) {
        padding: 7px;
    }
}



/*International Authorization*/

.auth {
    background-color: rgb(0, 112, 193, 0.2);
}
.tw{
    margin-left: 70px;
    @media (max-width: 992px) {
        margin-left: 0px;

        
    
    }
}
.ts{
    margin-right: 70px;
    @media (max-width: 992px) {
        margin-right: 0px;

        
    
    }
}
.auth-img{
padding: 30px 0px 30px 70px;
@media (max-width: 992px) {

    padding: 0px 0px 0px 0px;

}

}
.aft-img{
    padding: 0px 70px 30px 0px;
    @media (max-width: 992px) {
    
        padding: 0px 0px 0px 0px;
    
    }
    
    }

.img-css {
    height: 100%;
    /* Height for small screens */
    object-fit: cover;
    /* Maintain aspect ratio */

    border-radius: none;


    @media (min-width: 768px) {

        height: 100%;
        /* Adjust height for medium screens */

    }



    @media (min-width: 992px) {

        height: 100%;
        /* Larger height for large screens */
        border-radius: 30px;

    }
}

.tb h2 {
    text-decoration: underline;
    word-spacing: 1px;
    font-size: 1.3rem;
    font-weight: 600;

    @media (min-width: 768px) {
        font-size: 1.5rem;
        /* Larger font size for medium screens */
    }

    /* Media query for large screens (desktops) */
    @media (min-width: 992px) {
        margin-top: 5%;
        word-spacing: 1px;
        letter-spacing: 0px;
        font-size: 1.5rem;
        /* Even larger font size for large screens */
    }

    /* Media query for extra-large screens */
    @media (min-width: 1200px) {
        margin-top: 5%;
        font-size: 1.9rem;
        /* Largest font size for very large screens */
    }


    @media (min-width: 1550px) {
        margin-top: 10%;
        font-size: 2.3rem;
        /* Largest font size for very large screens */
    }
}
.tb{
    display: flex;
    flex-direction: column;
}

.tb p {

    color: rgb(97, 97, 97);
    font-size: 0.8rem;
    word-spacing: 2px;

    @media (min-width: 768px) {
        font-size: 0.9rem;
        /* Larger font size for medium screens */
    }

    /* Media query for large screens (desktops) */
    @media (min-width: 992px) {
        margin-top: 5%;
        font-size: 1.1rem;
        /* Even larger font size for large screens */
    }

    /* Media query for extra-large screens */
    @media (min-width: 1200px) {
        margin-top: 5%;
        font-size: 1.1rem;
        /* Largest font size for very large screens */
    }

    @media (min-width: 1550px) {
        margin-top: 2%;
        font-size: 1.9rem;
        margin-bottom: 3%;
        /* Largest font size for very large screens */
    }





}

.btn-custom {
    background-color: rgb(15, 15, 99);
    color: aliceblue;
    padding: 15px 20px;
    cursor: pointer;
}

.btn-custom:hover {
    color: rgb(179, 179, 179);
}



/*Experience component*/
.exp-box {
    text-align: center;
    /* Center text below the image */
}

/* Container for circular image */
.img-container {
    width: 70px;
    height: 70px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 auto;

    /* Extra small devices (phones, less than 576px) */
    @media (max-width: 575.98px) {
        width: 65px;
        height: 65px;
    }

    /* Small devices (tablets, 576px and up) */
    @media (min-width: 576px) and (max-width: 767.98px) {
        width: 100px;
        height: 100px;
    }

    /* Medium devices (desktops, 768px and up) */
    @media (min-width: 768px) and (max-width: 991.98px) {
        width: 110px;
        height: 110px;
    }

    /* Large devices (large desktops, 992px and up) */
    @media (min-width: 992px) and (max-width: 1199.98px) {
        width: 120px;
        height: 120px;
    }

    /* Extra large devices (extra large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        width: 130px;
        height: 130px;
    }
}


/* Circular image styling */
.exp-img {

    width: 70%;
    /* Adjust as needed to fit within the circular container */
    height: auto;
    /* Maintain aspect ratio */
}

/* Text styling */
.text-center {
    font-family: "Quicksand", sans-serif;
    font-weight: bold;
    color: rgb(79, 79, 79);
    font-size: 1.0rem;

    /* Extra small devices (phones, less than 576px) */
    @media (max-width: 575.98px) {
        font-size: 0.8rem;
    }

    /* Small devices (tablets, 576px and up) */
    @media (min-width: 576px) and (max-width: 767.98px) {
        font-size: 0.9rem;
    }

    /* Medium devices (desktops, 768px and up) */
    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 1.0rem;
    }

    /* Large devices (large desktops, 992px and up) */
    @media (min-width: 992px) and (max-width: 1199.98px) {
        font-size: 1.1rem;
    }

    /* Extra large devices (extra large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        font-size: 1.2rem;
    }
}

.num {
    font-size: 1.3rem;
    font-weight: bolder;

    /* Extra small devices (phones, less than 576px) */
    @media (max-width: 575.98px) {
        font-size: 1.0rem;
    }

    /* Small devices (tablets, 576px and up) */
    @media (min-width: 576px) and (max-width: 767.98px) {
        font-size: 1.1rem;
    }

    /* Medium devices (desktops, 768px and up) */
    @media (min-width: 768px) and (max-width: 991.98px) {
        font-size: 1.2rem;
    }

    /* Large devices (large desktops, 992px and up) */
    @media (min-width: 992px) and (max-width: 1199.98px) {
        font-size: 1.3rem;
    }

    /* Extra large devices (extra large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        font-size: 1.4rem;
    }
}


.bg-calender {
    background-color: #414040;
    /* Red background for calendar */
}

.bg-staff {
    background-color: #21a1a1;
    /* Blue background for staff */
}

.bg-agreement {
    background-color: #502323;
    /* Green background for agreement */
}

.bg-generator {
    background-color: #202e5f;
    /* Orange background for generator */
}

.bg-solar {
    background-color: #5bab26;
    /* Purple background for solar */
}



/*Logos Component*/

.logo-img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    /* Adjust to cover, contain, or any other desired value */
}

.custom-h1 {
    font-size: 2.1rem;
    font-weight: 600;
    color: black;
    text-transform: uppercase;
}

.custom-h3 {
    color: #2791d8;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.0rem;
}

/*Card Component*/
.custom-card {
    justify-content: center;

}

.custom-card h5 {
    text-decoration: underline;
    text-align: center;
    color: #202e5f;
}

.custom-card p {
    font-size: 0.8rem;
    text-align: center;
    color: #202e5f;
}

.card-component {
    background-color: #202e5f;
}

.card-component h1 {
    font-size: 2.1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: aliceblue;
}

.card-component h3 {
    color: #2791d8;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.0rem;
    color: aliceblue;
}

.card {
    display: flex;
    box-shadow: 0 8px 16px rgba(250, 248, 248, 0.2);
    /* Adds a subtle shadow */
    transition: box-shadow 0.3s ease-in-out;
    /* Smooth transition for shadow */
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    /* Ensures the card takes the full height of the parent */
}

.card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    /* Darker shadow on hover */
    transform: scale(1.02);
}

.card-img-top {
    height: 180px;
    max-height: 200px;
    /* Adjust max height for the image */
    object-fit: cover;
    /* Ensure the image covers the designated area */
}

.custom-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
}

.card-title {
    font-size: 1.0rem;
    margin-bottom: 0.5rem;
}

.card-text {
    flex-grow: 1;
    margin-bottom: 0;
}


/* Media Queries for Small Screens */
@media screen and (max-width: 1024px) {
    .content {
        flex-direction: column-reverse;
        /* Stack items vertically */
        align-items: center;
    }

    .about-image {
        width: 100%;
        /* Full width on small screens */
        height: auto;
        border-radius: 0;
    }

    .about-image img {
        border-radius: 0;
    }

    .text-box {
        max-width: 90%;
        /* Ensure it doesn’t exceed the container */
        padding: 1rem;
        /* Adjust padding for small screens */
        transform: none;
        /* Remove transform */
        margin: 0;
        height: auto;
        border-radius: 0;
        min-width: 100%;
        box-shadow: none;
        padding-bottom: 5px;
        border-bottom: 1px solid grey;
    }

    .text-box h2 {
        font-size: 1.5rem;
        /* Adjust font size */
    }

    .text-box p {
        font-size: 0.9rem;
        /* Adjust font size */
    }

    .text-box .button {
        padding: 0.5rem 1rem;
        /* Adjust padding */
    }
}


/*AboutUS component*/
.txt-box {

    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    position: relative;
    /* Ensure it can be layered correctly */
}

.text-box-about-us {
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    /* Use max-width instead of fixed width */
    width: 100%;
    /* Ensure it can shrink if needed */
    max-height: 280px;
    /* Use max-height instead of fixed height */
    height: auto;
    /* Allow height to adjust based on content */
    transform: translate(100px, 0px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    position: relative;
    /* Ensure it can be layered correctly */
    z-index: 2;
    /* Higher value to ensure it's on top */
    padding: 20px;
    /* Added padding for content spacing */
    box-sizing: border-box;
    /* Ensure padding is included in width/height calculation */
}

.text-box-about-us h5 {
    color: #202e5f;
}
.Image-container {
    width: 100%;
    margin-right: 200px;
    height: 400px;
    background-color: #202e5f;
    overflow: hidden;
    position: relative;
    /* Ensure it can be layered correctly */
    z-index: 1;
    /* Lower value to ensure the text box is on top */
}

.Image-container img {
    position: absolute;
    /* Ensure it covers the container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the container */
    
}
.text-box-about-us h2 {
    
    font-weight: 600;
}

@media (max-width: 768px) {
    .text-box-about-us h5 {
        margin-top: 8px;
        font-size: 0.5rem;
    }

    .text-box-about-us h2 {
        font-size: 1.4rem;
    }

    .text-box-about-us p {
        font-size: 0.9rem;
        ;
    }

    .text-box-about-us {
        width: 100%;
        transform: translate(0, 0);
        /* No translation for smaller screens */
        max-height: 300px;
        /* Adjust max-height for smaller screens if needed */
    }

    .Image-container {
        margin-right: 0;
    }
}

@media (max-width: 992px) {
    .text-box-about-us h5 {
        font-size: 1.0rem;
    }

    .text-box-about-us h2 {
        font-size: 1.5rem;
    }

    .text-box-about-us p {
        font-size: 0.9rem;
        ;
    }

    .text-box-about-us {
        width: 100%;
        transform: translate(0, 0);
        max-height: 300px;
        border-radius: 0;

    }

    .Image-container {
        margin-right: 0;
    }
}




/*Events Page*/



@keyframes scrollX {
    from {
        left: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}